body {
  width: 100% !important;
  --background-color: #18181b;
  --text-color: #a1a1aa;
  --card-background-color: rgba(255, 255, 255, 0.015);
  --card-border-color: rgba(255, 255, 255, 0.1);
  --card-box-shadow-1: rgba(0, 0, 0, 0.05);
  --card-box-shadow-1-y: 3px;
  --card-box-shadow-1-blur: 6px;
  --card-box-shadow-2: rgba(0, 0, 0, 0.1);
  --card-box-shadow-2-y: 8px;
  --card-box-shadow-2-blur: 15px;
  --card-label-color: #ffffff;
  --card-icon-color: #d4d4d8;
  --card-icon-background-color: rgba(255, 255, 255, 0.08);
  --card-icon-border-color: rgba(255, 255, 255, 0.12);
  --card-shine-opacity: 0.1;
  --card-shine-gradient: conic-gradient(
    from 205deg at 50% 50%,
    rgba(16, 185, 129, 0) 0deg,
    #10b981 25deg,
    rgba(52, 211, 153, 0.18) 295deg,
    rgba(16, 185, 129, 0) 360deg
  );
  --card-line-color: #2a2b2c;
  --card-tile-color: rgba(16, 185, 129, 0.05);
  --card-hover-border-color: rgba(255, 255, 255, 0.2);
  --card-hover-box-shadow-1: rgba(0, 0, 0, 0.04);
  --card-hover-box-shadow-1-y: 5px;
  --card-hover-box-shadow-1-blur: 10px;
  --card-hover-box-shadow-2: rgba(0, 0, 0, 0.3);
  --card-hover-box-shadow-2-y: 15px;
  --card-hover-box-shadow-2-blur: 25px;
  --card-hover-icon-color: #34d399;
  --card-hover-icon-background-color: rgba(52, 211, 153, 0.1);
  --card-hover-icon-border-color: rgba(52, 211, 153, 0.2);
  --blur-opacity: 0.01;
}

.card {
  min-width: 210px;
  background-color: var(--background-color);
  box-shadow: 0px var(--card-box-shadow-1-y) var(--card-box-shadow-1-blur)
      var(--card-box-shadow-1),
    0px var(--card-box-shadow-2-y) var(--card-box-shadow-2-blur)
      var(--card-box-shadow-2),
    0 0 0 1px var(--card-border-color);
  margin: 10px;
  border-radius: 15px;
  text-align: center;
  cursor: pointer;
  position: relative;
  transition: box-shadow 0.25s;
}
.card::before {
  content: "";
  position: absolute;
  inset: 0;
  border-radius: 15px;
  background-color: var(--card-background-color);
}
.card .icon {
  z-index: 2;
  position: relative;
  display: table;
  padding: 8px;
}
.card .icon::after {
  content: "";
  position: absolute;
  inset: 4.5px;
  border-radius: 50%;
  background-color: var(--card-icon-background-color);
  border: 1px solid var(--card-icon-border-color);
  -webkit-backdrop-filter: blur(2px);
  backdrop-filter: blur(2px);
  transition: background-color 0.25s, border-color 0.25s;
}
.card .icon svg {
  position: relative;
  z-index: 1;
  display: block;
  width: 24px;
  height: 24px;
  transform: translateZ(0);
  color: var(--card-icon-color);
  transition: color 0.25s;
}
.card h4 {
  z-index: 2;
  position: relative;
  margin: 12px 0 4px 0;
  font-family: inherit;
  font-weight: 600;
  font-size: 14px;
  line-height: 2;
  color: var(--card-label-color);
}
.card p {
  z-index: 2;
  position: relative;
  margin: 0;
  font-size: 1.2rem;
  line-height: 1.7;
  color: var(--text-color);
}
.card .desc {
  z-index: 2;
  position: relative;
  margin: 0;
  font-size: 14px;
  line-height: 1.7;
  color: var(--text-color);
}
.card .shine {
  border-radius: inherit;
  position: absolute;
  inset: 0;
  z-index: 1;
  overflow: hidden;
  opacity: 0;
  transition: opacity 0.5s;
}
.card .shine:before {
  content: "";
  width: 150%;
  padding-bottom: 150%;
  border-radius: 50%;
  position: absolute;
  left: 50%;
  bottom: 55%;
  filter: blur(35px);
  opacity: var(--card-shine-opacity);
  transform: translateX(-50%);
  background-image: var(--card-shine-gradient);
}
.card .background {
  border-radius: inherit;
  position: absolute;
  inset: 0;
  overflow: hidden;
  -webkit-mask-image: radial-gradient(
    circle at 60% 5%,
    black 0%,
    black 15%,
    transparent 60%
  );
  mask-image: radial-gradient(
    circle at 60% 5%,
    black 0%,
    black 15%,
    transparent 60%
  );
}
.card .background .tiles {
  opacity: 0;
  transition: opacity 0.25s;
}
.card .background .tiles .tile {
  position: absolute;
  background-color: var(--card-tile-color);
  -webkit-animation-duration: 8s;
  animation-duration: 8s;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  opacity: 0;
}
.card .background .tiles .tile.tile-4,
.card .background .tiles .tile.tile-6,
.card .background .tiles .tile.tile-10 {
  -webkit-animation-delay: -2s;
  animation-delay: -2s;
}
.card .background .tiles .tile.tile-3,
.card .background .tiles .tile.tile-5,
.card .background .tiles .tile.tile-8 {
  -webkit-animation-delay: -4s;
  animation-delay: -4s;
}
.card .background .tiles .tile.tile-2,
.card .background .tiles .tile.tile-9 {
  -webkit-animation-delay: -6s;
  animation-delay: -6s;
}
.card .background .tiles .tile.tile-1 {
  top: 0;
  left: 0;
  height: 10%;
  width: 22.5%;
}
.card .background .tiles .tile.tile-2 {
  top: 0;
  left: 22.5%;
  height: 10%;
  width: 27.5%;
}
.card .background .tiles .tile.tile-3 {
  top: 0;
  left: 50%;
  height: 10%;
  width: 27.5%;
}
.card .background .tiles .tile.tile-4 {
  top: 0;
  left: 77.5%;
  height: 10%;
  width: 22.5%;
}
.card .background .tiles .tile.tile-5 {
  top: 10%;
  left: 0;
  height: 22.5%;
  width: 22.5%;
}
.card .background .tiles .tile.tile-6 {
  top: 10%;
  left: 22.5%;
  height: 22.5%;
  width: 27.5%;
}
.card .background .tiles .tile.tile-7 {
  top: 10%;
  left: 50%;
  height: 22.5%;
  width: 27.5%;
}
.card .background .tiles .tile.tile-8 {
  top: 10%;
  left: 77.5%;
  height: 22.5%;
  width: 22.5%;
}
.card .background .tiles .tile.tile-9 {
  top: 32.5%;
  left: 50%;
  height: 22.5%;
  width: 27.5%;
}
.card .background .tiles .tile.tile-10 {
  top: 32.5%;
  left: 77.5%;
  height: 22.5%;
  width: 22.5%;
}
@-webkit-keyframes tile {
  0%,
  12.5%,
  100% {
    opacity: 1;
  }
  25%,
  82.5% {
    opacity: 0;
  }
}
@keyframes tile {
  0%,
  12.5%,
  100% {
    opacity: 1;
  }
  25%,
  82.5% {
    opacity: 0;
  }
}
.card .background .line {
  position: absolute;
  inset: 0;
  opacity: 0;
  transition: opacity 0.35s;
}
.card .background .line:before,
.card .background .line:after {
  content: "";
  position: absolute;
  background-color: var(--card-line-color);
  transition: transform 0.35s;
}
.card .background .line:before {
  left: 0;
  right: 0;
  height: 1px;
  transform-origin: 0 50%;
  transform: scaleX(0);
}
.card .background .line:after {
  top: 0;
  bottom: 0;
  width: 1px;
  transform-origin: 50% 0;
  transform: scaleY(0);
}
.card .background .line.line-1:before {
  top: 10%;
}
.card .background .line.line-1:after {
  left: 22.5%;
}
.card .background .line.line-1:before,
.card .background .line.line-1:after {
  transition-delay: 0.3s;
}
.card .background .line.line-2:before {
  top: 32.5%;
}
.card .background .line.line-2:after {
  left: 50%;
}
.card .background .line.line-2:before,
.card .background .line.line-2:after {
  transition-delay: 0.15s;
}
.card .background .line.line-3:before {
  top: 55%;
}
.card .background .line.line-3:after {
  right: 22.5%;
}
.card:hover {
  box-shadow: 0px 3px 6px var(--card-hover-box-shadow-1),
    0px var(--card-hover-box-shadow-2-y) var(--card-hover-box-shadow-2-blur)
      var(--card-hover-box-shadow-2),
    0 0 0 1px var(--card-hover-border-color);
}
.card:hover .icon::after {
  background-color: var(--card-hover-icon-background-color);
  border-color: var(--card-hover-icon-border-color);
}
.card:hover .icon svg {
  color: var(--card-hover-icon-color);
}
.card:hover .shine {
  opacity: 1;
  transition-duration: 0.5s;
  transition-delay: 0s;
}
.card:hover .background .tiles {
  opacity: 1;
  transition-delay: 0.25s;
}
.card:hover .background .tiles .tile {
  -webkit-animation-name: tile;
  animation-name: tile;
}
.card:hover .background .line {
  opacity: 1;
  transition-duration: 0.15s;
}
.card:hover .background .line:before {
  transform: scaleX(1);
}
.card:hover .background .line:after {
  transform: scaleY(1);
}
.card:hover .background .line.line-1:before,
.card:hover .background .line.line-1:after {
  transition-delay: 0s;
}
.card:hover .background .line.line-2:before,
.card:hover .background .line.line-2:after {
  transition-delay: 0.15s;
}
.card:hover .background .line.line-3:before,
.card:hover .background .line.line-3:after {
  transition-delay: 0.3s;
}

.day-night {
  cursor: pointer;
  position: absolute;
  right: 20px;
  top: 20px;
  opacity: 0.3;
}
.day-night input {
  display: none;
}
.day-night input + div {
  border-radius: 50%;
  width: 20px;
  height: 20px;
  position: relative;
  box-shadow: inset 8px -8px 0 0 var(--text-color);
  transform: scale(1) rotate(-2deg);
  transition: box-shadow 0.5s ease 0s, transform 0.4s ease 0.1s;
}
.day-night input + div:before {
  content: "";
  width: inherit;
  height: inherit;
  border-radius: inherit;
  position: absolute;
  left: 0;
  top: 0;
  transition: background-color 0.3s ease;
}
.day-night input + div:after {
  content: "";
  width: 6px;
  height: 6px;
  border-radius: 50%;
  margin: -3px 0 0 -3px;
  position: absolute;
  top: 50%;
  left: 50%;
  box-shadow: 0 -23px 0 var(--text-color), 0 23px 0 var(--text-color),
    23px 0 0 var(--text-color), -23px 0 0 var(--text-color),
    15px 15px 0 var(--text-color), -15px 15px 0 var(--text-color),
    15px -15px 0 var(--text-color), -15px -15px 0 var(--text-color);
  transform: scale(0);
  transition: all 0.3s ease;
}
.day-night input:checked + div {
  box-shadow: inset 20px -20px 0 0 var(--text-color);
  transform: scale(0.5) rotate(0deg);
  transition: transform 0.3s ease 0.1s, box-shadow 0.2s ease 0s;
}
.day-night input:checked + div:before {
  background: var(--text-color);
  transition: background-color 0.3s ease 0.1s;
}
.day-night input:checked + div:after {
  transform: scale(1);
  transition: transform 0.5s ease 0.15s;
}

body {
  font-family: "Inter", Arial;
  background-color: var(--background-color) !important;
  overflow: hidden;

}

h1 {
  position: absolute;
  top: 0;
  text-align: center;
  padding-top: 100px;
  color: #f7f9f9;
}



.section {
  position: relative;
  height: 60px;
  width: 80px;
  border-radius: 7px;
  background-color: #95a5a6;
}

.section.color_red {
  background-color: #ff3822;
  box-shadow: 0 0 60px 10px #ff3822;
}

.section.color_yellow {
  background-color: #eeff03;
  box-shadow: 0 0 60px 10px #eeff03;
}

.section.color_green {
  background-color: #06ff59;
  box-shadow: 0 0 60px 10px #06ff59;
}

.glass-card {
  margin: 3px;
  padding-top: 10px;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 10px;
  background: rgba(255, 255, 255, 0.2);
  border-radius: 7px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border: 1px solid rgba(255, 255, 255, 0.3);
}

.glass-card-label {
  font-size: medium;
}

.glass-card-description {
  width: 100%;
  text-align: center;
  margin-top: 10px;
  font-size: large;
}

.heartbeat {
  position: absolute;
  width: fit-content;
  background-color: #02E0A4;
  border-radius: 40px;
  width: 0.5rem;
  height: 0.5rem;
  opacity: 0.75;
  animation: ping 1s cubic-bezier(0, 0, 0.2, 1) infinite;
}

.dot {
  position: relative;
  width: fit-content;
  background: linear-gradient(135deg, #70E3C4, #02E0A4 48%, #03CD97);
  border-radius: 40px;
  width: 0.5rem;
  height: 0.5rem;
}

@keyframes ping {
  75%,
  100% {
    transform: scale(2);
    opacity: 0;
  }
}

.arrows-body {
  position: relative;
  display: 'flex';
  align-items: 'center';
  justify-content: 'center';
  flex-direction: row;
}

.arrow {
  position: relative;
  width: 18px;
  height: 18px;
  border-top: 6px solid #f7f9f9;
  border-left: 6px solid #f7f9f9;
  transform: rotate(-45deg);
  animation: arrow-load 2s infinite;

}

.arrow:nth-child(1) {
  left: 20px;
  animation-delay: -0.4s;
}

.arrow:nth-child(2) {
  animation-delay: -0.2s;
}

.arrow:nth-child(3) {
  right: 20px;
}

@keyframes arrow-load {
  0% {
    opacity: 0;
    transform: rotate(-45deg) translate(18px, 18px);
  }
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: rotate(-45deg) translate(-18px, -18px);
  }
  
}